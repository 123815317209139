import React from "react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker, Polyline } from 'react-google-maps';  
import {getStartEndMarkers, createPolyline, createBounds, noBounds} from '../logic/index.js';

const UserMap = (props) => {
  
  let bounds = props.itinerary ? createBounds(props.itinerary, props) : noBounds(props)

  const style = {
    borderRadius: 10,
    height: 620,
    flex: 1,
    zIndex: 1,
    position: 'relative'
  }

  const mapStyles = 
  [
    {
      "featureType": "administrative",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.attraction",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.medical",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.medical",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.place_of_worship",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.school",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.sports_complex",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]

  const defaultMapOptions = {
      styles: mapStyles
  };

  return (
    <GoogleMap 
        defaultZoom={13} 
        defaultCenter={{lat: 45.5087, lng: -73.554}}
        style={style}
        googleMapURL={props.googleMapURL}
        defaultOptions={defaultMapOptions}
        ref={(map) => { 
          if (props.itinerary && map) map.fitBounds(bounds);  
        }}
    >
        {getStartEndMarkers(props.itinerary, props).map(item => 
            <Marker
                key={item.lat}
                position={item}
            />
        )}
        {props.itinerary ? createPolyline(props.itinerary).map((item, index) => 
            <Polyline 
                key={index}
                path={item[0].coords}
                options={{
                    strokeColor: item[0].couleur ? item[0].couleur : 'black',
                    strokeOpacity: 0.75,
                    strokeWeight: 6,
                    
                }}
            />) 
          : null
        }
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(UserMap))

export default WrappedMap;