import ReactGA from 'react-ga';
import React from "react";
import styled from 'styled-components';
import ChronoHorizontal from '../../../assets/ChronoHorizontal.png'
import appleTopLogo from '../../../assets/appleTopLogo.png'
import androidTopLogo from '../../../assets/androidTopLogo.png'
import { withTranslation } from 'react-i18next';
import {smartphoneIcons} from '../../helpers/icons/index.js';
import '../styles/index.css'

const NavMenuItems = styled.label`
    height: 43px;
    color: #00213C;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: 800;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const DownloadLogo = styled.img`
    height: 22px;
    margin-left: 8px;
`
const handleChange = (props) => {

    let language = props.languageSettings.language;
    let setLanguage = props.languageSettings.setLanguage;

    if (language === "FR") {
        setLanguage("EN");
        props.getLang("EN");
        return language;
    }

    setLanguage("FR");
    props.getLang("FR");
    return language;
}

const appleStoreClick = (props) => {
    const {t} = props;
    ReactGA.event({
        category: 'Navbar Apple Download',
        action: 'Navigated to Apple store from navbar'
    });
    window.open(
        `https://apps.apple.com/ca/app/chrono-bus-m%C3%A9tro-et-train/id1261397728?l=${t('navbar.downloadLanguage')}`,
        '_blank'
    );
}


const androidStoreClick = (props) => {
    const {t} = props;
    ReactGA.event({
        category: 'Navbar Android Download',
        action: 'Navigated to Google play store from navbar'
    });
    window.open(
        `https://play.google.com/store/apps/details?id=quebec.artm.chrono\n&hl=${t('navbar.downloadLanguage')}_CA`,
        '_blank'
    );
}

const NavBar = (props) => {
    const {t} = props;
    
    return (
        <div id="navContainer">
            <div id="leftNavContainer">
                <a href="http://artm.quebec/" target="_blank" rel="noopener noreferrer">
                    <img id="chronoLogo" src={ChronoHorizontal} alt="Chrono Logo"/>
                </a>             
                <div id="logoText">
                    {t('navbar.title')}
                </div>
            </div>
  
        <div id="rightNavContainer">
            <div>
                <NavMenuItems onClick={() => window.open('https://www.artm.quebec/chrono-blogue/', '_blank') }>
                    {t('navbar.blog')}
                </NavMenuItems>
            </div>
            <div>

                <NavMenuItems>
                    <label style={{cursor: 'pointer'}} id="downloadAppText" onClick={() => window.location.href = ('#telechargerApplication')}>{t('navbar.download') }</label>
                    <div id="navLogosContainer">
                        <DownloadLogo src={appleTopLogo} onClick={() => appleStoreClick(props)}/>
                        <DownloadLogo src={androidTopLogo} onClick={() => androidStoreClick(props) }/>
                    </div>
                </NavMenuItems>

            </div>
            <div>
            <NavMenuItems onClick={() => handleChange(props)}>
                {props.languageSettings.language === "FR" ? "EN" : "FR"}
            </NavMenuItems>
            </div>
        </div>
  
      </div>
    )
}

export default withTranslation('common')(NavBar);
