import ReactGA from 'react-ga';

import React, { useState } from 'react';
import {Planificateur} from './components/planificateur';
import {NavBar} from './components/navbar';
import {Intro} from './components/intro';
import {Footer} from './components/footer';
import { withTranslation } from 'react-i18next';
import './index.css';
import { LocaleProvider } from "antd";

import 'antd/es/locale/fr_FR';
import 'antd/es/locale/en_US';

import fr_FR from "antd/lib/locale-provider/fr_FR";
import en_US from "antd/lib/locale-provider/en_US";

import "moment/locale/fr";


ReactGA.initialize('UA-153482824-1', {
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});

ReactGA.pageview(window.location.pathname + window.location.search);


const handleGetLan = (lang, props, setUpdatedLocale) => {
  props.i18n.changeLanguage(lang);
  if (lang === "EN") setUpdatedLocale(en_US)
  else setUpdatedLocale(fr_FR)
}

const App = (props) => {
  const [language, setLanguage] = useState("FR");
  const [updatedLocale, setUpdatedLocale] = useState(fr_FR);
  window.onload = function() {document.body.scrollTop = document.documentElement.scrollTop = 0;};

  
  return (
    <div id="AppContainer">
        <LocaleProvider locale={updatedLocale}>
          <NavBar getLang={(lang) => handleGetLan(lang, props, setUpdatedLocale)} languageSettings={{language, setLanguage}} /> 
          <Intro />
          <Planificateur language={language} />
          <Footer />
        </LocaleProvider>
    </div>
  );
}

export default withTranslation('common')(App);