import ReactGA from 'react-ga';
import React from "react";
import IlluHero from '../../../assets/IlluHero.png';
import appleLogo from '../../../assets/appleLogo.svg';
import androidLogo from '../../../assets/androidLogo.svg';
import { withTranslation } from 'react-i18next';
import ARTM from '../../../assets/ARTM.png';
import EXO from '../../../assets/EXO.png';
import RTL from '../../../assets/RTL.png';
import STL from '../../../assets/STL.png';
import STM from '../../../assets/STM.png';
import styled from "styled-components";
import "../styles/Footer.css"

const FooterText = styled.label`
    font-size: 20px;
    color: #00213c;
    font-weight: bold;
    font-family: Nunito;
    margin-top: 4vw;
    margin-bottom: 3vw;
    width: 100%;
`

const PhoneHero = styled.img`
    width: 100%;
`

const FooterLinks = styled.p`
    font-size: 16px;
    color: #00c6b8;
    font-weight: bold;
    font-family: Nunito;
    text-decoration: underline;
    cursor: pointer;
`

const FooterFAQ = styled.p`
    font-size: 16px;
    color: #00213c;
    font-family: Nunito;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 4vw;
`

const RetroActionText = styled.p`
    color:rgb(0, 33, 60);
    font-family:Nunito, Tahoma, Geneva, sans-serif;
    font-size:16px;
    line-height:28.8px;
    margin-bottom:30px;
    text-align:center;
    width: 80%;
`

const ARTMLogo = styled.img`
    vertical-align: middle;
    max-width: 100%;
`

const FooterLogos = styled.img`

    max-width: 160px;
`

const appleStoreClick = () => {
    ReactGA.event({
        category: 'Footer Apple Download',
        action: 'Navigated to Apple store from footer'
    });
}


const androidStoreClick = () => {
    ReactGA.event({
        category: 'Footer Android Download',
        action: 'Navigated to Google play store from footer'
    });
}


const Footer = (props) => {
    const {t} = props
    return (      
        <div id="telechargerApplication">
            <div id="footerTextDownload">
                {t('footer.downloadApp')}
            </div>
            <div id="buttonContainer">
                <a className="downloadButtons" onClick={() => appleStoreClick()} href={`https://apps.apple.com/ca/app/chrono-bus-m%C3%A9tro-et-train/id1261397728?l=${t('footer.downloadAppLang')}`} target="_blank"  rel="noopener noreferrer">
                    <img className="logos" src={appleLogo} alt="iPhone" />
                    <label>iPhone</label>
                </a>
                <a className="downloadButtons" onClick={() => androidStoreClick()}  href={`https://play.google.com/store/apps/details?id=quebec.artm.chrono\n&hl=${t('footer.downloadAppLang')}_CA`} target="_blank" rel="noopener noreferrer">
                    <img className="logos" src={androidLogo} alt="android" />
                    <label>Android</label>
                </a>
            </div>   

            {/* <div id="footerHeader">{t('footer.contentTitle')}</div>
            <div id="heroMediaContainer"><PhoneHero src={IlluHero} alt="telephone" /></div>
            
            <FooterText>
                {t('footer.simpleTool')}
            </FooterText>
             */}
             
            <FooterLinks onClick={() => window.open("https://www.artm.quebec/", '_blank')}>
                {t('footer.returnToSite')}
            </FooterLinks>
        
            <FooterLinks onClick={() => window.open(`${t('footer.privacyLink')}`, '_blank')}>
                {t('footer.privacy')}
            </FooterLinks>
            
            <RetroActionText>
                {t('footer.feedback')}
                <strong style={{color: 'rgb(0, 198, 184)', textDecoration: 'underline'}}><a  href={`mailto:${t('footer.feedbackEmail')}`} rel="noopener noreferrer" target="_top">{t('footer.feedbackEmail')}</a></strong>
            </RetroActionText>
            
            <FooterFAQ onClick={() => window.open( `${t('footer.faqLink')}` )}>
                {t('footer.FAQ')}
            </FooterFAQ>
   
            <div className="footer__logos">
                <ARTMLogo src={ARTM} alt="ARTM" />
                <div className="footer__logos-container">
                    <FooterLogos src={EXO} alt="EXO" />
                    <FooterLogos src={RTL} alt="RTL" />
                    <FooterLogos src={STL} alt="STL" />
                    <FooterLogos src={STM} alt="STM" />
                </div>
            </div>
            <hr id="footerDivider"></hr>
            <div id="footnotes">
                <p id={t('intro.onClickStartSearch')}><i>{t('footer.note')}</i></p>
                <p>{t('footer.notice')}</p>
            </div>
        </div>
    )
}

export default withTranslation('common')(Footer);