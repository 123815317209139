import ReactGA from 'react-ga';
import React from "react";
import City from '../../../assets/City.png';
import IlluTraveler from '../../../assets/IlluTraveler.png';
import IlluWalk from '../../../assets/IlluWalk.png';
import { withTranslation, Trans } from 'react-i18next';
import { Button, Row } from "antd";
import "../styles/index.css";

const mobiliySiteClick = () => {
    ReactGA.event({
        category: 'Mobility Montreal Website Click',
        action: 'Navigated to Mobility Montreal website from navbar'
    });
}

const Intro = (props) => {
    const {t} = props;
    return (
            <div id="introContainer">
                {/* <img src={City} alt="city" id="city" />
                <div id="coupleOnPhone" style={{backgroundImage: `url(${IlluTraveler})`, backgroundRepeat: 'no-repeat', backgroundSize: '90%'}} /> */}
                <div id="introBodyTextContainer">
                    <div id="introHeaderText">
                        {t('intro.title')}
                    </div>
                    <div id="introBodyText">
                        <Trans i18nKey="mobilityWebsite">
                                <p>{t('intro.content.line1')}
                                    <strong>
                                        <a href={t('intro.content.url')} target="_blank" rel="noopener noreferrer" onClick={() => mobiliySiteClick()}>
                                            {t('intro.content.website')}
                                        </a>
                                    </strong>
                                </p>
                        </Trans>
                    </div>
                    <Button type="primary" id="startButton" onClick={() => {
                            window.location.href = (`#${t('intro.onClickStartSearch')}`);
                            window._input.focus();
                        }}>
                        {t('intro.start')}
                    </Button>
                </div>    
                {/* <div id="ladyWalkingContainer">
                    <div id="ladyWalking" style={{backgroundImage: `url(${IlluWalk})`, backgroundRepeat: 'no-repeat', backgroundSize: '90%'}} />
                </div> */}
                <Row id="rowDivider" />
          </div>
        
    )
}

export default withTranslation('common')(Intro);