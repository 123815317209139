import React, {useState, useEffect} from "react";
import {Card, Checkbox, Button, DatePicker, TimePicker, AutoComplete, Form, Col, Row, Select, Spin, Icon } from "antd";
import styled from 'styled-components';
import "../styles/Planificateur.css";
import moment from "moment";
import {getSuggestions, getItinerary} from "../logic/index.js"
import { UserMap } from "../../userMap";
import { Steps } from "../../steps";
import { withTranslation } from 'react-i18next';

const Option = AutoComplete.Option;

const Labels = styled.label`
    height: 20px;	
    width: 107px;	
    opacity: 0.5;	
    color: #00213C;	
    font-family: Nunito;	
    font-size: 12px;	
    font-weight: bold;	
    letter-spacing: 0.18px;	
    line-height: 26px;
`

const CheckboxLabel = styled.label`
    height: 22px;	
    width: 80.2px;	
    color: #000F2D;	
    font-family: Nunito;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 21px;
`

const handleSubmit = (e, props, setLoading, setItinerary, language, setNoResult, setFormData) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll( async(err, values) => {
      if (!err) {       
          try {
            setLoading(true);
            const data = {
                ...values, 
                language 
            }
            
            setFormData(data);
            let response = await getItinerary(data);
            if (response.responseStatus || response.reponse.statut === "ZERO_RESULTS") {
                setNoResult(true);
                setLoading(false);
                return;
            }

            setLoading(false);
            setItinerary(response.reponse.itineraires)
            window.location.href = ('#stepsContainer');

            return;
          } catch (error) {
              console.log('error', error)
            setLoading(false)
          }    
      }
    });
};

const onSearch = async (searchText, setSuggestions) => {
    if (!searchText || searchText.length < 3) setSuggestions([]);
    else {
        let response = await getSuggestions(searchText);
        setSuggestions(response.reponse);
    }
};

const handleReset = (props, setItinerary, setNoResult, setLoading) => {
    setLoading(false);
    props.form.resetFields();
    setItinerary([]);
    setNoResult(false);
};

const handlePanelChange = (panelKey, setPanel) => {
    setPanel(panelKey);
}

const validateTravelHour = (rule, value, callback, props) => {

    if (!value) {
        return callback();
    }
    
    const {t} = props;

    const travelDate = props.form.getFieldValue('travelDate');
    const now = moment();

    if (moment(travelDate).isAfter(now, 'day')) {
        callback();
        return;
    }
    
    if (!travelDate || moment(travelDate).isSame(now, 'day')) {
        let selectedHour = Number(moment(value).format('HH'));
        let currentHour = moment().hour();
        let selectedMinute = Number(moment(value).format("mm"));
        let currentMinute = moment().minute();

        if (selectedHour > currentHour ) {
            callback();
            return;
        }

        else if (selectedHour === currentHour) {
            
            if (selectedMinute >= currentMinute) {
                callback();
                return;
            }
            else return callback(t('planner.errors.earlyHour'));
        }
        
        else return callback(t('planner.errors.earlyHour'));
    }

    callback(t('planner.errors.earlyHour'));

}

const validateTravelDate = (rule, value, callback, props) => {

    if (!value) {
        return callback();
    }

    const {t} = props;
    let now = moment();
    
    if (moment(value).isSameOrAfter(now, 'day')) {   
        props.form.validateFields(['travelTime']);
        callback();
        return;
    }

    callback(t("planner.errors.earlyDate"));
}

const languageChange = async (itinerary, formData, setItinerary, props, setLoading) => {
    if (itinerary.length > 0) {
        setLoading(true);

        const data = {
            ...formData, 
            language: props.language
        }

        const response = await getItinerary(data);
        setItinerary(response.reponse.itineraires);
        setLoading(false);
    }
}

const Planificateur = (props) => {

    const {t, language} = props;

    const key = 'AIzaSyDKDVYk-kZE2eCxlN9f-r9iAmhhCul6LaE';
    
    const {getFieldDecorator} = props.form;
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [itinerary, setItinerary] = useState([]);
    const [noResult, setNoResult] = useState(false);
    const [panel, setPanel] = useState(null);
    const [formData, setFormData] = useState({});

    const [startingAddress, setStartingAddress] = useState("");
    const [destinationAddress, setDestinationAddress] = useState("");

    useEffect(() => {
        languageChange(itinerary, formData, setItinerary, props, setLoading);
    }, [language]);


    useEffect(() => {
        setPanel("0")
    }, [formData]);


    const children = suggestions.map((item) => {
        return <Option key={item.lieuId}>{item.adresseSimple}</Option>
    });

    return (
        <div id="plannerContainer">
            
            <div id="startSearch" />
            
            <div id="plannerBodyContainer">
                <div id="plannerCard">
                    <Form onSubmit={(e) => handleSubmit(e, props, setLoading, setItinerary, language, setNoResult, setFormData)} hideRequiredMark={true} colon={false} autoComplete="off">

                    <input type="hidden" value="noAutocomplete" />
                        <Card 
                        size="small" 
                        style={{borderRadius: 7}}
                        extra={<span onClick={() => handleReset(props, setItinerary, setNoResult, setLoading)} style={{color: '#0E506B', cursor: "pointer", fontSize: 20}}><Icon type="reload" /></span>}
                        actions={[ 
                            noResult ?
                                <div> 
                                    <p id="noResult">{t('planner.errors.noResult')}</p>
                                    <Button id="noResultButton" ghost type="danger" onClick={() => handleReset(props, setItinerary, setNoResult, setLoading)}>{t('planner.restart')}</Button>
                                </div> 
                            :
                                <Form.Item>
                                    <Button type="primary" id="rechercherBtn" htmlType="submit" loading={loading}>{t('planner.search')}</Button>
                                </Form.Item>
                        ]}>
                            <Col>
                                <Form.Item label={<Labels>{t('planner.departure')}</Labels>}>
                                    {getFieldDecorator('startingAddress', {
                                            rules: [{ required: true, message: t('planner.errors.startingAddress') }],
                                    })(
                                        <AutoComplete
                                            autoFocus
                                            size="large" 
                                            onSearch={(value) => onSearch(value, setSuggestions)}
                                            placeholder={t('planner.departureAddress')}
                                            onChange={(evt, stm) => setStartingAddress(stm.props.children)}
                                            ref={c => (window._input = c)}
                                            autoComplete="off"
                                        >
                                            {children}
                                        </AutoComplete>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item label={<Labels>{t('planner.arrival')}</Labels>}>
                                    {getFieldDecorator('destinationAddress', {
                                            rules: [{ required: true, message: t('planner.errors.arrivalAddress') }],
                                        })(
                                        <AutoComplete
                                            size="large" 
                                            onSearch={(value) => onSearch(value, setSuggestions)}
                                            placeholder={t('planner.arrivalAddress')}
                                            onChange={(evt, stm) => setDestinationAddress(stm.props.children)}
                                        >
                                            {children}
                                        </AutoComplete>
                                        )}
                                </Form.Item>
                            </Col>
                            <Form.Item label={<Labels>{t('planner.transport')}</Labels>}>
                                <div id="travelContainer">
                                    <Form.Item>                      
                                        {getFieldDecorator('travelBus', {
                                            initialValue: true,
                                        })(
                                            <Checkbox defaultChecked={true}><CheckboxLabel>{t('planner.bus')}</CheckboxLabel></Checkbox>
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('travelMetro', {
                                            initialValue: true,
                                        })(
                                            <Checkbox defaultChecked={true}><CheckboxLabel>{t('planner.subway')}</CheckboxLabel></Checkbox>
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('travelTrain', {
                                            initialValue: true,
                                        })(
                                            <Checkbox defaultChecked={true}><CheckboxLabel>{t('planner.train')}</CheckboxLabel></Checkbox>
                                        )}
                                    </Form.Item>
                                </div>
                            </Form.Item>

                            <Form.Item label={<Labels>{t('planner.date')}</Labels>}>
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <Form.Item>
                                            {getFieldDecorator('departureOrArrival', {
                                                initialValue: 'départ',
                                            })(
                                                <Select
                                                >
                                                    <Option value="départ">{t('planner.start')}</Option>
                                                    <Option value="arrivée">{t('planner.end')}</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item>
                                            {getFieldDecorator('travelDate', {
                                                initialValue: null,
                                                rules: [{
                                                    validator: (rule, value, callback) => validateTravelDate(rule, value, callback, props)
                                                }]
                                            })(
                                                <DatePicker style={{width: '100%'}} format="L" placeholder={moment().format("DD/MM/YYYY")} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item>
                                            {getFieldDecorator('travelTime', {
                                                initialValue: null,
                                                rules: [{
                                                    validator: (rule, value, callback) => validateTravelHour(rule, value, callback, props)
                                                }]
                                            })(
                                                <TimePicker format={'HH:mm'} placeholder={moment().format("HH:mm")} style={{width: '100%'}} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Card>
                    </Form>
                </div>
                <div id="mapContainer">
                    {
                        language === 'EN' &&
                        <UserMap 
                            itinerary={itinerary[panel ? panel : null]} 
                            formData={panel ? panel : null} 
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&language=en`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: 620 }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            language={language}
                        />
                    }
                    {
                        language === 'FR' &&
                        <UserMap 
                            itinerary={itinerary[panel ? panel : null]} 
                            formData={panel ? panel : null} 
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&language=fr`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: 620 }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            language={language}
                        />
                    }
                </div>
            </div>

            <Row id="stepsContainer">
                <Col style={{marginTop: itinerary.length > 0 ? 100 : 40}}>
                    {loading ? <Spin /> : 
                        <Steps itinerary={itinerary} addresses={{startingAddress, destinationAddress}} selectedPanel={(panelKey) => handlePanelChange(panelKey, setPanel)} />
                    }
                </Col>
            </Row>
      </div>
    )
}

export default withTranslation('common')(Form.create()(Planificateur));