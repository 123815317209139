import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_FR from "./translations/FR/common.json";
import common_EN from "./translations/EN/common.json";

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'FR',
    defaultNS : 'common',
    react: {
        useSuspense: false
    },
    resources: {
        FR: {
            common: common_FR
        },
        EN: {
            common: common_EN
        }
    }
});

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>,
document.getElementById('root'));