import decodePolyline from 'decode-google-map-polyline';

export const decodeLines = (string) => {
    return decodePolyline(string);
}

export const getStartEndMarkers = (itinerary) => {
    let startEndMarkers = [];
    if (itinerary) {
        startEndMarkers = [{lat: itinerary.departLatitude, lng: itinerary.departLongitude}, {lat: itinerary.arriveeLatitude, lng: itinerary.arriveeLongitude}];
    }
    return startEndMarkers;
}

export const createPolyline = (itinerary) => {

    let result = [];
    if (itinerary) {
        result = itinerary.jalons.map((coords, index) => {
            return result.concat({coords: decodeLines(coords.pointsPolyligne), couleur: coords.couleur})
        })
        return result;
    }   
}

export const createBounds = (itinerary) => {

    if (!itinerary) {
        return null;
    }
    else {

        let listBounds = []
        itinerary.jalons.map(item => listBounds.push({lat: item.departLatitude, lng: item.departLongitude}, {lat: item.arriveeLatitude, lng: item.arriveeLongitude}))

        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < listBounds.length; i++) {
            bounds.extend(listBounds[i]);
        }
        
        return bounds;
    }
}

export const noBounds = (props) => {

    let points = [
        {lat: 45.517212, lng: -73.5727583},
        {lat: 45.5012507, lng: -73.5648425}
    ]
    
    let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }
        
    return bounds;
}
