import React, {useState} from 'react';
import { Collapse, Icon } from 'antd';
import {selectedIcons, headerSelectedIcons} from '../../helpers/icons/index.js';
import styled from "styled-components";
import pointList from '../../../assets/pointList.png';
import {secondsToHumanReadableTime, metersToHumanReadbleDistance} from '../logic/index.js';
import {formatHour} from '../../helpers/formatTime';

const { Panel } = Collapse;

const StepContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 0 0 80px;
`

const StepHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px; 
    margin-bottom: 10px;
`

const LeftSideHours = styled.span`
    display: flex;
    align-items: center;
    flex: 0 0 80px;
`

const MiddleStep = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 40px;
`

const StartingPoint = styled.img`
    height: 18px;
`

const StartingPosition = styled.div`
    margin-left: 20px;
    height: auto;
    color: #00213C;
    font-family: Nunito;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
`

const StepDetails = styled.div`
    margin-left: 20px;
`

const getColor = (item) => {
    return !item.couleur ? "black" : item.couleur;
}

const getRoute = (item) => {
    if (!item.routeID && !item.routeNomCourt) return null;
    return item.routeNomCourt ? item.routeNomCourt : item.routeID ? item.routeID : null

}

const routeStyling = (item) => {
    return {
        color: getColor(item),
        paddingLeft: 3,
        paddingTop: 2
    }
}

const listSteps = (steps, addresses) => {

    const stepDisplay = (
        <div style={{marginTop: 10, marginBottom: 10}}>
            <StepHeader>
                <LeftSideHours>{formatHour(steps.heureDepart)}</LeftSideHours>
                <MiddleStep>
                    <StartingPoint src={pointList} alt="start" />
                </MiddleStep>
                <StartingPosition>{addresses.startingAddress}</StartingPosition>
            </StepHeader>
            {
                steps.jalons.map((item, index) => {
                    return (
                        <div key={index} style={{marginTop: 20, marginBottom: 20}}>
                            {
                                item.arretDepart ? 
                                    <StepContainer>
                                        <LeftSideHours>{formatHour(item.heureDepart)}</LeftSideHours>
                                        <MiddleStep>
                                            {selectedIcons([{ couleur: item.couleur, typeVehicule: item.typeVehicule, routeID: getRoute(item) }])}
                                        </MiddleStep>
                                        <StartingPosition>{item.arretDepart}</StartingPosition>
                                    </StepContainer>
                                : null
                            }
                            <StepContainer>
                                <LeftSideHours>
                                    {selectedIcons([{ couleur: getColor(item), typeVehicule: item.typeVehicule ? item.typeVehicule : "WALKING", routeID: getRoute(item) }])}
                                    {
                                        item.routeNomCourt ? <span style={routeStyling(item)}>{item.routeNomCourt}</span> 
                                        : item.routeID ? <span style={routeStyling(item)}>{item.routeID}</span> 
                                        :  null
                                    }
                                </LeftSideHours>
                                <MiddleStep>
                                
                                    <div style={{height: '4vh', width: 6, backgroundColor: getColor(item), borderRadius: 10 }} />
                                
                                </MiddleStep>
                                <StepDetails>
                                    {item.mode === "WALKING" ? item.sommaire : item.directionTransit}
                                        <br />
                                    {
                                        item.mode ==="WALKING" ? null : <span>{item.agenceCode}<br /></span>
                                    }
                                    <span>{secondsToHumanReadableTime(item.dureeSecondes)} / {metersToHumanReadbleDistance(item.distanceMetres)}</span>
                                </StepDetails>
                            </StepContainer>
                            {
                                item.arretArrivee ? 
                                    <StepContainer>
                                        <LeftSideHours>{formatHour(item.heureArrivee)}</LeftSideHours>
                                        <MiddleStep>
                                            {selectedIcons([{couleur: item.couleur, typeVehicule: item.typeVehicule, routeID: getRoute(item) }])}
                                        </MiddleStep>
                                        <StartingPosition>{item.arretArrivee}</StartingPosition>
                                    </StepContainer>

                                : null
                            }
                        </div>
                    )
                })
            }
            <StepContainer>
                <LeftSideHours>{formatHour(steps.heureArrivee)}</LeftSideHours>
                <MiddleStep><StartingPoint src={pointList} alt="start" /></MiddleStep>
                <StartingPosition>{addresses.destinationAddress}</StartingPosition>
            </StepContainer>
        </div>
    )
    return stepDisplay;
}

const headerSteps = (item) => {
    
    let headerObj = {};
    const headerIcons = item.jalons.map(item => {
        if (item.couleur) return {...headerObj, couleur: item.couleur, typeVehicule: item.typeVehicule, routeID: getRoute(item) }
        else return {...headerObj, couleur: 'black', typeVehicule: "WALKING"}
    })

    let result = headerSelectedIcons(headerIcons)

    return result;
}

const handlePanelChange = ( key, setSelectedKey, selectedPanel ) => {
    setSelectedKey(key);
    selectedPanel(key);
}

const panelHeaders = (props, selectedKey, setSelectedKey) => {

    if (props.itinerary.length > 0) {
        return (
            <Collapse accordion activeKey={selectedKey} onChange={key => handlePanelChange(key, setSelectedKey, props.selectedPanel) } expandIconPosition='right'> 
                {props.itinerary.map((item, index) => {
                    return (
                        <Panel key={index} header={
                            <div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: 15}}>
                                        <Icon type="clock-circle" />
                                    </div>
                                    <div style={{marginRight: 10}}>{`${formatHour(item.heureDepart)} - ${formatHour(item.heureArrivee)}`} </div>
                                    <div style={{marginRight: 10, fontWeight: 'bold'}}>{item.dureeTexte}</div>
                                </div>
                                <div style={{paddingTop: 10}}>
                                    {headerSteps(item).map((item, index) => <span key={index}>{item}</span>)}
                                </div>
                            </div>
                            }>
                            <div>
                                {listSteps(item, props.addresses)}
                            </div>
                        </Panel> 
                    )
                })}
            </Collapse>
        )
    }   
}

const Steps = (props) => {
    const [selectedKey, setSelectedKey] = useState('0')

    return (
        <div id="itineraires">
            {panelHeaders(props, selectedKey, setSelectedKey)}
        </div>
    )
}

export default Steps;
